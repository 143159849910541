<template>
  <div>
    <v-row class="align-center">
      <!-- Left Half: Image -->
      <v-col cols="12" sm="4" class="d-flex">
        <img
          src="@/assets/thingsToDo/WTH.jpg"
          alt="SouthWest Middle School"
          class="school-logo"
        />
      </v-col>

      <!-- Right Half: School Name -->
      <v-col cols="12" sm="6" class="d-flex left">
        <h1 class="school-name">Riverhead Central School District </h1>
      </v-col>
    </v-row>
    <!-- Divider Line (Optional, just to separate sections visually) -->
    <v-divider class="my-4"></v-divider>

    <!-- Content Section: "What are we raising money for?" -->
    <v-row>
      <v-col cols="12">
        <div class="heading">
          <div class="question-title">What are we raising money for?</div>
          <div class="hLine"></div>
        </div>

        <!-- Paragraph Section -->
        <div class="paragraphMessage">
          <p>
            We are excited to announce that the students of
            <strong>SouthWest Middle School</strong> are teaming up with the
            <strong>World Famous Harlem Wizards</strong> to help us raise funds
            for the <strong>SWMS PTO</strong> with their new
            <strong>WizFit Challenge</strong> fundraiser and kids experience...
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CampaignMessageDistrict",
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.school-name {
  font-size: 2.3rem;
  font-weight: bold;
  color: #2c1963;
  margin: 0; /* Remove default margins */
  padding-left: 10px; /* Optional: a small space between the image and name */
}

.heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #3f51b5;
  margin-top: 20px;
  margin-left: 5%;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.paragraphMessage {
  font-size: 1.1rem;
  margin-top: 20px;
  line-height: 1.6;
  margin-left: 5%;
}

.question-title {
  color: #2c1963;
  font-weight: bold;
}
.school-logo {
  width: 100%;
  max-width: 250px; /* Adjust this to fit your image size */
  display: block;
  margin-left: 15%;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    background-color: #2c1963;
    width: 30px;
    height: 3px;
    float: right;
  }
}
</style>
